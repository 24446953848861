import { Injectable } from '@angular/core';
import { AngularFireMessaging  } from '@angular/fire/compat/messaging';
// import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs'
import swal from 'sweetalert2';
import "firebase/messaging"
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { DataSharingService } from '../services/data.share.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  // inviteHtml=`<div class="row" [routerLink]="`+this.redirect(data)+`")"><div class="col-9 pr-0"><p>`+data.impactCircleName+` Invite</p><p>`+data.title+`</p><h4>`+data.message+`</h4></div><div class="col-3 pl-0"><img src="../../../assets/images/notification.png"></div></div>`:`<div class="row" [routerLink]="`+this.redirect(data)+`")"><div class="col-9 pr-0"><h4>`+data.title+`</h4><p>`+data.message+`</p></div><div class="col-3 pl-0"><img src="../../../assets/images/notification.png"></div></div>`;

  // notificationCount: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private notificationCount: BehaviorSubject<any>;
  public count: Observable<any>;

  fireBase = new BehaviorSubject(null)
  checkNotification = new BehaviorSubject(null);
  checkMessageNotification = new BehaviorSubject(null);
  notificationHeader: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private dataSharingService: DataSharingService, 
              private angularFireMessaging: AngularFireMessaging, 
              private router: Router, 
              private db: AngularFireDatabase) {
    this.notificationCount = new BehaviorSubject<any>(localStorage.getItem('notificationCount') || 0);
    this.count = this.notificationCount.asObservable();
    this.angularFireMessaging.messages.subscribe(
      (_messaging:any) => {
        // console.log("_messaging----->>>>>>>>", _messaging)
        // _messaging = _messaging.onMessage.bind(_messaging);
        // _messaging. = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

  }
  requestPermission() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log("Async Work Complete");

        this.angularFireMessaging.requestToken.subscribe(
          (token: any) => {

            // console.log("firebase token-----------------------------------", token);
            localStorage.setItem('deviceToken', token || '')
            // return token;
            return resolve(token);
          },
          (err: any) => {
            // console.error('Unable to get permission to notify.', err);
          }
        );
      }, 1000);
    });
    return promise;
  }

  receiveMessage() {
    // console.log("new message received.1 ");
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // console.log("new message received. ", payload.data);
        let data = payload.data

        // console.log(data)
        // if(payload.data.title === 'Purchaser Awaited?') {
        //   this.toastr.info(payload.data.body);
        // } else {
        //   this.toastr.success(payload.data.body);
        // }
        // this.notificationHeader.next(data);
        // this.currentMessage.next(payload);
        this.dataSharingService.notificationHeader.next(true);
        this.dataSharingService.notificationPage.next(true);
        // this.checkMessageNotification.next(data)
        // this.dataSharingService.notificationData.next(data);

        this.showAlert1(data)
        this.setNotificationCount(1);
       
      })
  }
  showAlert1(data: any) {
    // console.log('data', data)
    
    swal.fire({ 
      toast: true,
      position: "top-right",
      // width:'200px',
      showConfirmButton: false,
      showCancelButton: false,
      // cancelButtonText: 'x',
      customClass: {
        popup:'Notification_msg'},
      timer: 5000,
      background: "#fff",
      html: this.htmlReturn(data),
      // type: "success",
    })

   

  }
  htmlReturn(data: any) {
 
    if (data.notificationType == 1 || data.notificationType == 2 || data.notificationType == 3 || data.notificationType == 4) {
      return `<div class="row mes_set"  ng-reflect-router-link="`+this.redirect(data)+`" routerLink="`+this.redirect(data)+`"><div class="col-12 pr-0"><p>` + data.title + `</p><h4>` + data.message + `</h4></div>`
    } else {
      return `<div class="row mes_set" ng-reflect-router-link="`+this.redirect(data)+`"  routerLink="`+this.redirect(data)+`"><div class="col-12 pr-0"><p>` + data.title + `</p><h4>` + data.message + `</h4></div>`

    }
  }

  
  redirect(data: any) {
    // console.log(data)
    if(data.notificationType == 1 || data.notificationType == 2 || data.notificationType == 3){
      // this.router.navigate(["my-profile" ]);
      return '/my-profile'
    }else if(data.notificationType == 4){
      // this.router.navigate(["team" ]);
      return '/team'
    }else{
      // this.router.navigate(["notifications" ]);
      return '/notifications'
    }


  }
  getnotificationCount(count: any) {
    this.notificationCount.next(count);
  }

  setNotificationCount(count: any) {
    const notificationCount = Number(localStorage.getItem('notificationCount')) + count
    // console.log("notificationCount--->>",notificationCount)
    localStorage.setItem('notificationCount', notificationCount.toString())
    this.notificationCount.next(notificationCount);
  }
}